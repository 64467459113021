<template>
    <div>
        <ValidationObserver ref="form">
            <b-row>
                <!--details page student-->
                <b-col cols="12" md="12" lg="8">
                    <b-card>
                        <b-card-header
                            class="student-details-card__header position-relative"
                            header-class="p-0 mb-2"
                        >
                            <div
                                class="student-details-card__title-container d-flex"
                            >
                                <unicon
                                    name="users-alt"
                                    width="22"
                                    height="22"
                                />
                                <h4
                                    class="student-details-card__title ml-1 mb-0"
                                >
                                    تفاصيل حساب الطالب
                                </h4>
                            </div>
                            <div
                                class="student-details-card__teacher-status-container d-flex align-items-center"
                            >
                                <b-badge
                                    pill
                                    :variant="
                                        studentDto.isSub ? 'success' : 'danger'
                                    "
                                >
                                    {{
                                        studentDto.isSub ? " مفعل" : "غير مفعل"
                                    }}
                                </b-badge>
                            </div>
                        </b-card-header>
                        <b-row>
                            <b-col md="6" lg="6">
                                <ek-input-text
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'اسم الطالب مطلوب',
                                        },
                                    ]"
                                    name="name"
                                    placeholder="أدخل اسم الطالب"
                                    label="اسم الطالب  "
                                    v-model="studentDto.name"
                                ></ek-input-text>
                            </b-col>
                            <b-col md="6" lg="6">
                                <ek-input-text
                                    :rules="[
                                        {
                                            type: 'mobile',
                                            message:
                                                'يرجى ادخال رقم الموبايل صحيح',
                                        },
                                    ]"
                                    name="phoneNumber"
                                    placeholder="أدخل رقم الموبايل "
                                    label="رقم الموبايل"
                                    v-model="studentDto.phoneNumber"
                                ></ek-input-text> </b-col
                        ></b-row>
                        <b-row>
                            <b-col cols="12" md="6" lg="6">
                                <ek-date-picker
                                    name="تاريخ الميلاد "
                                    placeholder="ادخل تاريخ الميلاد "
                                    label="تاريخ الميلاد "
                                    v-model="studentDto.birthdate"
                                ></ek-date-picker>
                            </b-col>

                            <b-col cols="12" md="6" lg="6">
                                <ek-input-text
                                    name="email"
                                    placeholder="ادخل البريد الإلكتروني"
                                    label=" البريد الإلكتروني"
                                    v-model="studentDto.email"
                                ></ek-input-text>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="6" lg="6">
                                <ek-input-text
                                    name="password"
                                    placeholder="******** "
                                    label=" كلمة السر الجديدة"
                                    v-model="studentDto.newPassword"
                                    :rules="[
                                        {
                                            type: 'min:8',
                                            message:
                                                'كلمة المرور يجب ان تحوي 8 محارف على الاقل',
                                        },
                                    ]"
                                ></ek-input-text>
                            </b-col>

                            <b-col cols="12" md="6" lg="6">
                                <div>
                                    <label
                                        class="pb-1"
                                        style="
                                            font-size: 14px;
                                            padding-top: 8px;
                                        "
                                    >
                                        الجنس:</label
                                    >
                                    <b-form-radio-group
                                        v-model="studentDto.gender"
                                        :options="options"
                                        value-field="item"
                                        text-field="name"
                                    ></b-form-radio-group>
                                </div>
                            </b-col>
                        </b-row>
                        <div class="d-block">
                            <label
                                style="
                                    font-size: 14px;
                                    margin-top: 7px;
                                    margin-right: 13px;
                                "
                                >الجامعات والكليات المشترك بها :</label
                            >
                            <div
                                v-if="studentDto.universities.length > 0"
                                class="d-flex"
                            >
                                <b-col
                                    cols="12"
                                    lg="4"
                                    v-for="item in studentDto.universities"
                                    :key="item.id"
                                >
                                    <b-badge
                                        style="padding: 5px; margin-top: 8px"
                                        pill
                                        variant="primary"
                                        >{{
                                            item.faculty +
                                            "-" +
                                            item.university +
                                            "-" +
                                            item.department +
                                            "-" +
                                            item.year
                                        }}
                                    </b-badge>
                                </b-col>
                            </div>
                            <div v-else>
                                <b-badge
                                    style="padding: 5px; margin-top: 8px"
                                    pill
                                    variant="primary"
                                    >لا يوجد
                                </b-badge>
                            </div>
                        </div>
                    </b-card>
                </b-col>
                <b-col cols="12" md="12" lg="4" class="position-relative">
                    <img
                        class="h-100 w-100 rounded"
                        style="object-fit: cover"
                        :src="
                            $store.getters['app/domainHost'] +
                            '/' +
                            studentDto.url
                        "
                        alt=""
                    />
                </b-col>
            </b-row>
        </ValidationObserver>

        <!--table packages-->
        <ek-table
        class="mt-2"
        :title="` الحزم المشتركة`"
        :columns="headerDetails"
        :items="studentDto.codes || []"
        @details="goToDetails"
        @delete-selected="deleteCode"
        >
            <template slot="items.startDate" slot-scope="{ value }">
                {{ value ? new Date(value).toLocaleDateString() : "_" }}
            </template>

            <template slot="items.years" slot-scope="{ props }">
                <b-button
                    :id="`popover-target-${props.row.originalIndex}`"
                    variant="transperant"
                >
                    مرر للرؤية
                </b-button>
                <b-popover
                    :target="`popover-target-${props.row.originalIndex}`"
                    triggers="hover"
                    placement="right"
                >
                    <span>
                        {{
                            props.row.years.length > 0
                                ? props.row.years.find((ele) => ele)
                                : "لايوجد"
                        }}
                    </span>
                </b-popover>
            </template>
        </ek-table>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
    components: {},
    props: {
        id: String,
    },
    data() {
        return {
            options: [
                { item: "Male", name: "ذكر" },
                { item: "Female", name: "انثى" },
            ],
        };
    },
    computed: {
        ...mapState({
            students: ({ students }) => students.students,
            studentDto: (state) => state.students.studentDto,
            classes: (state) => state.students.classes,
            statusStudentList: (state) => state.students.statusStudentList,
            headerDetails: (state) => state.students.headerDetails,
        }),
    },
    methods: {
        ...mapActions(["getStudentDetails","deleteCode"]),
        goToDetails({ row }) {
            this.$router.push(`/admin/codes/${row.packageId}`);
        },
    },
    created() {
        this.getStudentDetails(this.id);
    },
};
</script>
